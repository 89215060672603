import { ErrorMessages, IErrorMessage } from '../data/ErrorMessages';
import {
  ISubjectsAnnouncementsItem,
  ITypesCommunicate
} from '../data/ManagerPreferences/ManagerPreferences';
import {
  ICreateUserRequest,
  ICreateUserResponse
} from '../interfaces/user/ICreateUser';
import { ILoginResponse, INativeLoginRequest } from '../interfaces/user/ILogin';
import {
  IMergeAccountRequest,
  IMergeAccountResponse
} from '../interfaces/user/IMergeAccount';
import {
  AddressFull,
  IUpdateUserEmailRequest,
  IValidateIdentityRequest,
  IValidateIdentityResponse
} from '../model/Models';
import { getOriginDomain, pushGTMDataLayer } from '../utils/GenericFunctions';
import { SentryContextsEnum } from '../utils/Sentry';
import { GET, PATCH, POST, PUT, DELETE } from './api';
import { setCookie } from './cookieController';

const ACCOUNT_SERVICE = process.env.REACT_APP_BASE_URL_ACCOUNT_SERVICE;
const SSO_SERVICE = process.env.REACT_APP_BASE_URL_SSO_SERVICE;
const KEY_SESSION = process.env.REACT_APP_KEY_SESSION as string;
const KEY_REFRESH_SESSION = process.env.REACT_APP_KEY_REFRESH_SESSION as string;
const KEY_SESSION_ORIGIN = process.env.REACT_APP_KEY_SESSION_ORIGIN as string;
const KEY_SESSION_SOCIAL = process.env.REACT_APP_KEY_SESSION_SOCIAL as string;
const KEY_SESSION_PROVIDER = process.env
  .REACT_APP_KEY_SESSION_PROVIDER as string;

export async function createUser(
  data: ICreateUserRequest
): Promise<ICreateUserResponse> {
  data.deviceName = 'web';

  const response = await POST(
    `${ACCOUNT_SERVICE}/api/v2/users`,
    data,
    data.provider
      ? ErrorMessages.CREATE_ACCOUNT_SOCIAL
      : ErrorMessages.CREATE_ACCOUNT_NATIVE
  );
  return response.data;
}

export async function checkUserHasPassword(
  email: string,
  context?: SentryContextsEnum
) {
  const customErrorMessage = ErrorMessages.CHECK_ACCOUNT_HAS_PASSWORD;
  if (context) customErrorMessage.context = context;

  const response = await GET(
    `${SSO_SERVICE}/api/v1/account/password/${email}`,
    customErrorMessage
  );
  return response.data;
}

export async function signIn(
  email: string,
  password: string
): Promise<ILoginResponse> {
  const payload: INativeLoginRequest = {
    email,
    password
  };
  const response = await POST(
    `${SSO_SERVICE}/api/v2/users/login`,
    payload,
    ErrorMessages.LOGIN_NATIVE
  );

  const data: ILoginResponse = response.data;
  setCookie(KEY_SESSION, data.accessToken);
  setCookie(KEY_REFRESH_SESSION, data.refreshToken);
  setCookie(KEY_SESSION_ORIGIN, getOriginDomain());
  pushGTMDataLayer(data?.accessToken);
  return data;
}

export async function validateUserIdentity(payload: IValidateIdentityRequest) {
  payload.deviceName = 'web';

  const response: any = await POST(
    `${SSO_SERVICE}/api/v2/users/validate-identity`,
    payload,
    ErrorMessages.VALIDATE_USER_IDENTITY
  );

  const data: IValidateIdentityResponse = response.data;
  return data;
}

export async function updateUserEmail(payload: IUpdateUserEmailRequest) {
  const response: any = await PUT(
    `${SSO_SERVICE}/api/v2/users/email`,
    payload,
    ErrorMessages.UPDATE_ACCOUNT_EMAIL
  );

  const data = response.data;
  return data;
}

export async function updateCpf(email: string, cpf: string) {
  const response = await PUT(`${ACCOUNT_SERVICE}/api/v1/user/${email}/cpf`, {
    cpf
  });
  return response.data;
}

export async function updateTelephone(email: string, telephone: string) {
  const response = await PATCH(
    `${ACCOUNT_SERVICE}/api/v1/user`,
    {
      cellphone: telephone || null
    },
    ErrorMessages.UPDATE_ACCOUNT_TELEPHONE
  );
  return response.data;
}

export async function updateTelephoneComplement(
  email: string,
  telephone: string
) {
  const response = await PUT(
    `${ACCOUNT_SERVICE}/api/v1/user/${email}/telephone/complement`,
    {
      telephone: telephone || null
    }
  );
  return response.data;
}

export async function updateAddress(email: string, address: AddressFull) {
  const response = await PUT(
    `${ACCOUNT_SERVICE}/api/v1/account/address`,
    {
      ...address
    },
    ErrorMessages.UPDATE_ACCOUNT_ADDRESS
  );
  return response.data;
}

export async function updateAddressComplement(
  email: string,
  address: AddressFull
) {
  const response = await PUT(
    `${ACCOUNT_SERVICE}/api/v1/user/${email}/address/complement`,
    {
      ...address
    }
  );
  return response.data;
}

export async function mergeAccount(
  payload: IMergeAccountRequest,
  context?: SentryContextsEnum
): Promise<IMergeAccountResponse> {
  payload.deviceName = 'web';

  const customErrorMessage = ErrorMessages.MERGE_SOCIAL;
  if (context) customErrorMessage.context = context;

  const response = await POST(
    `${ACCOUNT_SERVICE}/api/v2/users/merge`,
    payload,
    customErrorMessage
  );

  const data: IMergeAccountResponse = response.data;
  if (data?.accessToken) {
    setCookie(KEY_SESSION, data.accessToken);
    setCookie(KEY_REFRESH_SESSION, data.refreshToken);
    setCookie(KEY_SESSION_SOCIAL, payload.providerToken);
    setCookie(KEY_SESSION_PROVIDER, payload.provider);
    setCookie(KEY_SESSION_ORIGIN, getOriginDomain());
  }

  return data;
}

export async function resetPassword(
  email: string,
  password: string,
  code: string
) {
  const response = await PATCH(
    `${ACCOUNT_SERVICE}/api/v1/user/reset-password`,
    {
      email,
      code,
      password: password
    },
    ErrorMessages.RESET_ACCOUNT_PASSWORD
  );
  return response.data;
}

export async function updatePassword(
  email: string,
  password: string,
  customErrorMessage: IErrorMessage = ErrorMessages.CHANGE_ACCOUNT_PASSWORD
) {
  const response = await PATCH(
    `${ACCOUNT_SERVICE}/api/v1/user`,
    {
      email,
      password: password
    },
    customErrorMessage
  );
  return response.data;
}

export async function logoutSSO(
  email: string,
  token: string,
  provider?: string
) {
  const response = await POST(`${SSO_SERVICE}/api/v1/account/logout`, {
    origin: getOriginDomain(),
    email,
    token,
    provider
  });

  return response.data;
}

export async function logoutAccountService(
  email: string,
  token: string,
  provider?: string
) {
  const response = await POST(`${ACCOUNT_SERVICE}/api/v1/account/logout`, {
    origin: getOriginDomain(),
    email,
    token,
    provider
  });
  return response.data;
}

export async function userConfirmPassword(
  email: string,
  password: string,
  context?: SentryContextsEnum
) {
  const customErrorMessage = ErrorMessages.CONFIRM_ACCOUNT_PASSWORD;
  if (context) customErrorMessage.context = context;

  const response = await POST(
    `${SSO_SERVICE}/api/v1/user/password`,
    {
      email,
      password
    },
    customErrorMessage
  );
  return response.data;
}

export async function updateUserInfos(email: string, data: any) {
  const response = await PATCH(`${ACCOUNT_SERVICE}/api/v1/user`, data);
  return response.data;
}

export async function getPreferencesAnnouncements(email: string) {
  const response = await GET(
    `${ACCOUNT_SERVICE}/api/v1/account/communication-preferences`,
    ErrorMessages.GET_COMMUNICATION_PREFERENCES
  );
  return response.data;
}

export async function updatePreferencesAnnouncements(
  email: string,
  data: ISubjectsAnnouncementsItem
) {
  const response = await PUT(
    `${ACCOUNT_SERVICE}/api/v1/account/communication-preferences`,
    data,
    ErrorMessages.UPDATE_COMMUNICATION_PREFERENCES
  );
  return response.data;
}

export async function getTypesCommunicate(email: string) {
  const response = await GET(
    `${ACCOUNT_SERVICE}/api/v1/account/optin`,
    ErrorMessages.GET_OPT_IN
  );
  return response.data;
}

export async function updateTypesCommunicate(
  email: string,
  data: ITypesCommunicate
) {
  const response = await PUT(
    `${ACCOUNT_SERVICE}/api/v1/account/optin`,
    data,
    ErrorMessages.UPDATE_OPT_IN
  );
  return response.data;
}

export async function getListAllSessions(email: string) {
  const response = await GET(`${SSO_SERVICE}/api/v1/user/${email}/session`);
  return response.data;
}

export async function removeSessions(email: string, sessionId: string) {
  const response = await DELETE(`${SSO_SERVICE}/api/v1/user/session`, {
    data: {
      email,
      sessionId
    }
  });

  return response.data;
}

export async function removeAllSessions(email: string) {
  const response = await DELETE(
    `${SSO_SERVICE}/api/v1/user/sessions/${email}`,
    {
      data: {}
    }
  );

  return response.data;
}

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import ButtonLabel from '../../../../components/Button/ButtonLabel';
import FormField from '../../../../components/FormField/FormField';
import { useUpdateUserDataContext } from '../../../../data/UpdateUserData/UpdateUserDataContext';
import { useControllerFlowStepsContext } from '../../../../hooks/ControllerFlowStepsContext';
import { useControlNavBarContext } from '../../../../hooks/ControlNavBar';
import { checkEmailExist } from '../../../../services/queryController';
import { createTokenValidateNewEmail } from '../../../../services/tokenValidation';

interface EditEmailNewEmailProps {
  previousPath?: number | null;
  nextPath: number | null;
  handleChangeStep(e: number): void;
}

export default function EditEmailNewEmail({
  previousPath,
  nextPath,
  handleChangeStep
}: EditEmailNewEmailProps) {
  const { register, getValues, errors, touchedFields, setError, trigger } =
    useUpdateUserDataContext();
  const { showCooldownErrorMessage, showErrorMessageServer } =
    useControllerFlowStepsContext();
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setShowButton(true);
    setGoBackNavigate({ path: '', param: {} });
  }, []);

  useEffect(() => {
    navigate('../novo-email', { relative: 'path' });
  }, [navigate]);

  window.onpopstate = () => {
    previousStep();
  };

  function previousStep() {
    if (previousPath == 0 || previousPath) {
      handleChangeStep(previousPath);
    }
  }

  function nextStep() {
    if (nextPath) handleChangeStep(nextPath);
  }

  async function validateNewEmail() {
    setIsLoading(true);
    if (isLoading) return;

    const email = getValues('email').trim();
    if (email && !errors.email) {
      try {
        const response: any = await checkEmailExist(email);
        if (response?.exist)
          setError('email', {
            message: 'endereço de e-mail já vinculado a uma conta.'
          });
        else if (response?.exist === false) {
          await createTokenValidateNewEmail(email);
          nextStep();
        }
      } catch (error: any) {
        console.error(error);
        if (error?.statusCode === 429) showCooldownErrorMessage();
        else showErrorMessageServer();
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function handleCheckEmailIsEqual() {
    await trigger(['email', 'checkEmail']);
  }

  return (
    <>
      <div className="flex flex-column">
        <h2 className="color-text-default">novo e-mail</h2>
        <span className="md mt-4 color-text-default">
          Informe o novo e-mail que você deseja utilizar em sua Conta SBT.
        </span>

        <div className="flex flex-column mt-16">
          <div className="form-container">
            <FormField
              name="email"
              label="novo e-mail"
              type="email"
              placeholder="digite seu e-mail"
              register={register('email')}
              error={errors}
              touchedFields={touchedFields}
              onBlur={handleCheckEmailIsEqual}
            />
          </div>
          <div className="form-container">
            <FormField
              name="checkEmail"
              label="confirme seu novo e-mail"
              type="email"
              placeholder="digite seu e-mail"
              register={register('checkEmail')}
              error={errors}
              touchedFields={touchedFields}
              onBlur={handleCheckEmailIsEqual}
            />
          </div>

          <Button
            className="mt-24 mbi-0"
            full
            disabled={
              isLoading ||
              !!errors.email ||
              !getValues('email') ||
              !getValues('checkEmail') ||
              !touchedFields.email ||
              !!errors.checkEmail
              //   fieldsValidate.checkPassword?.length < 6
            }
            onClick={validateNewEmail}
          >
            <ButtonLabel loading={isLoading}>continuar</ButtonLabel>
          </Button>
          <Button
            className="mt-16 mbi-0"
            full
            customColors="btn-white"
            disabled={isLoading}
            onClick={previousStep}
          >
            <ButtonLabel>voltar</ButtonLabel>
          </Button>
        </div>
      </div>
    </>
  );
}

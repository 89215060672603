import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import ButtonLabel from '../../../../components/Button/ButtonLabel';
import FormField from '../../../../components/FormField/FormField';
import { useUpdateUserDataContext } from '../../../../data/UpdateUserData/UpdateUserDataContext';
import { useControllerFlowStepsContext } from '../../../../hooks/ControllerFlowStepsContext';
import { useControlNavBarContext } from '../../../../hooks/ControlNavBar';
import { passwordMask } from '../../../../utils/Masks';
import { PASS_MAX_LENGTH } from '../../../../data/Constants';
import SocialMedia from '../../../../components/SocialMedia/SocialMedia';
import {
  SocialActionEnum,
  useControllerSocialMediaContext
} from '../../../../hooks/ControllerSocialMedia';
import { validateUserIdentity } from '../../../../services/user';
import { useAuthContext } from '../../../../hooks/AuthContext';

interface EditEmailAuthProps {
  previousPath?: number | null;
  nextPath: number | null;
  handleChangeStep(e: number): void;
}

export default function EditEmailAuth({
  nextPath,
  handleChangeStep
}: EditEmailAuthProps) {
  const {
    register,
    setValue,
    getValues,
    errors,
    touchedFields,
    watch,
    setError,
    sizeView
  } = useUpdateUserDataContext();
  const { observableChangeStatus, setObservableChangeStatus } =
    useControllerSocialMediaContext();
  const { checkAuth } = useAuthContext();
  const { showErrorMessageServer } = useControllerFlowStepsContext();
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fieldsValidate, setValueValidateFields] = useState({
    password: ''
  });

  const navigate = useNavigate();

  useEffect(() => {
    setShowButton(true);
  }, []);

  useEffect(() => {
    const subscription = watch((data: any) => {
      const fields = {
        password: data.password
      };
      setValueValidateFields(fields);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setGoBackNavigate({
      path: `/menu-de-conta/atualizar-dados${
        sizeView[0] < 576 ? '/conta' : ''
      }`,
      param: {}
    });
  }, [sizeView]);

  window.onpopstate = () => {
    goBack();
  };

  function goBack() {
    navigate(
      `/menu-de-conta/atualizar-dados${sizeView[0] < 576 ? '/conta' : ''}`,
      {
        replace: true
      }
    );
  }

  function nextStep() {
    if (nextPath) handleChangeStep(nextPath);
  }

  function redirectToForgetPassword() {
    const url = '/recuperar-senha';
    navigate(url);
  }

  useEffect(() => {
    if (observableChangeStatus.observing) {
      if (observableChangeStatus.success == 'OK') {
        setValue('code', observableChangeStatus?.code);
        nextStep();
      }

      setObservableChangeStatus({
        observing: false,
        success: '',
        provider: ''
      });
    }
  }, [observableChangeStatus.success]);

  async function submitLogin() {
    if (isLoading) return;
    setIsLoading(true);

    const email = checkAuth().user.email;
    const password = getValues('password');
    try {
      const response = await validateUserIdentity({
        email,
        password
      });

      if (response?.indentityValidated) {
        setValue('code', response?.code);
        nextStep();
      } else {
        setError('password', {
          message: 'senha incorreta'
        });
      }
    } catch (error) {
      console.log(error);
      showErrorMessageServer();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className="flex flex-column">
        <h2 className="color-text-default">alterar e-mail</h2>

        <span className="md mt-8">
          Para alterar o seu e-mail precisamos primeiro, confirmar a sua
          identidade.
        </span>

        <div className="mt-32 box-social__text">
          <hr />
          <span className="md">continuar com</span>
          <hr />
        </div>
        <SocialMedia label="ou" action={SocialActionEnum.VALIDATE_IDENTITY} />

        <div className="flex flex-column mt-16">
          <h2 className="color-text-default">confirmar senha</h2>

          <div className="form-container mt-8">
            <FormField
              name="password"
              label="senha"
              type="password"
              maxLength={PASS_MAX_LENGTH}
              placeholder="digite sua senha"
              register={register('password')}
              onInput={passwordMask}
              error={errors}
              touchedFields={touchedFields}
              sufix
              isPassword
            />
            <a
              className="sm-bold link no-decoration link-intern-text ml-3"
              rel="noreferrer"
              onClick={redirectToForgetPassword}
            >
              Esqueceu sua senha?
            </a>
          </div>

          <Button
            className="mt-16 mbi-0"
            full
            loading={isLoading}
            onClick={submitLogin}
            disabled={
              isLoading ||
              !fieldsValidate.password.length ||
              fieldsValidate.password.length < 6 ||
              !!errors.password
            }
          >
            <ButtonLabel loading={isLoading}>entrar</ButtonLabel>
          </Button>
          <Button
            className="mt-16 mbi-0"
            customColors="btn-white"
            full
            loading={isLoading}
            onClick={goBack}
          >
            <ButtonLabel>voltar</ButtonLabel>
          </Button>
        </div>
      </div>
    </>
  );
}

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../../../components/Card/Card';
import FormFieldToggle from '../../../../components/FormField/FormFieldToggle';
import { useManagerPreferencesContext } from '../../../../data/ManagerPreferences/ManagerPreferences';
import { useAuthContext } from '../../../../hooks/AuthContext';
import { useControllerFlowStepsContext } from '../../../../hooks/ControllerFlowStepsContext';
import { useControlNavBarContext } from '../../../../hooks/ControlNavBar';
import { getTypesCommunicate } from '../../../../services/user';

export default function CardTypeCommunicate() {
  const {
    checkTypeCommunicate,
    setcheckTypeCommunicate,
    copyTypesCommunicate,
    sizeView,
    isFormEqual,
    setshowModalPendingDataToSave,
    openModalPendingDataToSave
  } = useManagerPreferencesContext();
  const navigate = useNavigate();
  const { showErrorMessageServer } = useControllerFlowStepsContext();
  const { checkAuth } = useAuthContext();
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();

  function interceptorGoBack() {
    if (isFormEqual.equal) {
      navigate('/menu-de-conta/preferencias-de-comunicacao', {
        state: 'refresh'
      });
    } else {
      setshowModalPendingDataToSave(true);
      openModalPendingDataToSave();
    }
  }

  function configGoBack() {
    setGoBackNavigate({
      path: '/menu-de-conta/preferencias-de-comunicacao',
      param: { state: 'refresh' },
      condition: interceptorGoBack
    });
  }

  useEffect(() => {
    configGoBack();
  }, [isFormEqual.equal]);

  useEffect(() => {
    typesCommunicate();
    if (sizeView[0] <= 576) {
      setShowButton(true);
      configGoBack();
    }
  }, []);

  async function typesCommunicate() {
    try {
      const response: any = await getTypesCommunicate(checkAuth()?.user?.email);
      setcheckTypeCommunicate(() => ({
        email: response.optEmail,
        whatsapp: response.optWhatsapp,
        sms: response.optSms
      }));
      copyTypesCommunicate({
        email: response.optEmail,
        whatsapp: response.optWhatsapp,
        sms: response.optSms
      });
    } catch (error) {
      console.log(error);
      showErrorMessageServer();
    }
  }

  function changeStatus(type: string, status: boolean) {
    setcheckTypeCommunicate((state) => ({ ...state, [type]: status }));
  }

  return (
    <Card
      width="100%"
      paddingHorizontal="24"
      paddingVertical="24"
      id="typeCommunicate"
    >
      <div className="width-full flex flex-column">
        <div className="width-full">
          <h2 className="color-text-default mb-16">tipos de comunicados</h2>
          <span className="lg color-text-default">
            Controle quais tipos de comunicados você quer receber e tenha acesso
            a todas as novidades do SBT.
          </span>
          <br />
          <h6 className="lg color-text-default mt-8 mb-24">
            Suas alterações demoram até 72h para entrar em vigor.{' '}
          </h6>

          <div>
            <div>
              <span className="lg strong color-text-default">e-mails</span>
              <div className="mt-8">
                <FormFieldToggle
                  value={'email'}
                  checked={checkTypeCommunicate?.email}
                  callbackChange={(status: boolean) =>
                    changeStatus('email', status)
                  }
                />
                <span className="md ml-8 color-text-default">
                  {checkTypeCommunicate?.email ? 'ativado' : 'desativado'}
                </span>
              </div>
            </div>
            <div className="mt-24">
              <span className="lg strong color-text-default">SMS</span>
              <div className="mt-8">
                <FormFieldToggle
                  value={'sms'}
                  checked={checkTypeCommunicate?.sms}
                  callbackChange={(status: boolean) =>
                    changeStatus('sms', status)
                  }
                />
                <span className="md ml-8 color-text-default">
                  {checkTypeCommunicate?.sms ? 'ativado' : 'desativado'}
                </span>
              </div>
            </div>
            <div className="mt-24 mb-16">
              <span className="lg strong color-text-default">whatsapp</span>
              <div className="mt-8">
                <FormFieldToggle
                  value={'whatsapp'}
                  checked={checkTypeCommunicate?.whatsapp}
                  callbackChange={(status: boolean) =>
                    changeStatus('whatsapp', status)
                  }
                />
                <span className="md ml-8 color-text-default">
                  {checkTypeCommunicate?.whatsapp ? 'ativado' : 'desativado'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

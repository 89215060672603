import { useControlNavBarContext } from '../../hooks/ControlNavBar';
import Icon from '../Icon/Icon';
import LogoText from '../LogoText/LogoText';
import Menu from '../Menu/Menu';
import MenuItem from '../Menu/MenuItem';
import './NavBar.scss';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/AuthContext';
import { MAIS_SBT_URL, SBT_TV_URL } from '../../data/Constants';

export default function NavBar() {
  const { createRedirectURL } = useAuthContext();
  const { goBackNavigate, showButton } = useControlNavBarContext();

  const navigate = useNavigate();

  function goBack() {
    let link: any;
    if (goBackNavigate.condition) {
      goBackNavigate.condition();
    } else {
      link = goBackNavigate.path || -1;
      navigate(link, goBackNavigate.param);
    }
  }

  function redirectToSite(baseURL: string) {
    window.open(createRedirectURL(baseURL), '_self');
  }

  return (
    <div className="nav-bar">
      <div className="nav-bar__back ml-12">
        {showButton ? (
          <button
            data-testid="navbar-back-button"
            className="nav-bar-btn-icon"
            onClick={goBack}
          >
            <Icon name="arrowLeft2" />
          </button>
        ) : null}
      </div>
      <div
        data-testid="navbar-logo"
        className="nav-bar__logo"
        onClick={() => navigate('/menu-de-conta', { replace: true })}
      >
        <LogoText />
      </div>
      <div className="nav-bar__menu">
        <Menu label="ir para">
          <MenuItem icon="newTab" action={() => redirectToSite(MAIS_SBT_URL)}>
            +SBT
          </MenuItem>
          <MenuItem icon="newTab" action={() => redirectToSite(SBT_TV_URL)}>
            SBT TV
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}
